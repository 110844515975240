import { UnknownAction } from 'redux';

export const UI__UPDATE_STATE = 'UI__UPDATE_STATE';
export const UI__UPDATE_ACCOUNT_DETAILS_FIELD_OPEN_STATE = 'UI__UPDATE_ACCOUNT_DETAILS_FIELD_OPEN_STATE';

export interface AccountDetailsOpenFieldsState {
  password: boolean,
  emailAddress: boolean,
  telephoneNumber: boolean,
  firstName: boolean,
  contactAddress: boolean,
}
export interface UIState {
  message: string | null,
  level: 'success' | 'warning' | 'error' | null,
  loading: boolean,
  accountDetailsOpenFields: AccountDetailsOpenFieldsState
}

export const defaultUIState: UIState = {
  message: null,
  level: null,
  loading: false,
  /* This is not a global state but it's only be used in the AccountDetails
  scene to implement the toggle functionality and integrate it with the sagas. */
  accountDetailsOpenFields: {
    password: false,
    emailAddress: false,
    telephoneNumber: false,
    firstName: false,
    contactAddress: false,
  },
};

interface Action extends UnknownAction {
  type: string;
  payload?: any;
  meta?: Object;
  error?: boolean;
}

export const updateUIState = (state): Action => ({
  type: UI__UPDATE_STATE,
  payload: state,
});

export const toggleAccountDetailsFieldOpenState = (fieldName): Action => ({
  type: UI__UPDATE_ACCOUNT_DETAILS_FIELD_OPEN_STATE,
  payload: fieldName,
});

export const resetUIState = (): Action => ({
  type: UI__UPDATE_STATE,
  payload: defaultUIState,
});

export default (prevState = defaultUIState, action) => {
  switch (action.type) {
    case UI__UPDATE_STATE: {
      try {
        const level = action.payload.level || 'success';

        return {
          ...prevState,
          ...action.payload,
          level,
        };
      } catch (err) {
        return defaultUIState;
      }
    }
    case UI__UPDATE_ACCOUNT_DETAILS_FIELD_OPEN_STATE: {
      const { payload: toggledFieldName } = action;

      const newAccountDetailsOpenFields = {};

      Object.keys(prevState.accountDetailsOpenFields)
        .forEach((fieldName) => {
          const previousValue = prevState.accountDetailsOpenFields[fieldName];

          // toggle the selected one and set to false all the others
          newAccountDetailsOpenFields[fieldName] = (toggledFieldName === fieldName) ? ! previousValue : false;
        });

      return {
        ...prevState,
        accountDetailsOpenFields: newAccountDetailsOpenFields,
      };
    }

    default:
      return prevState;
  }
};
