import { getApiBaseUrl, API_VERSION } from 'config/api';
import { getJwT } from 'services/AuthenticationService';
import Snowplow, { AVALANCHE_TRACKER_NAME, PV_VALANCHE_TRACKER_NAME } from 'lib/snowplow';
import fetchWrapper from 'api/fetch';

let snowplow;

/**
 * This methods can be used for events that happen outside the login wall
 * @param eventName the Schema registry name of the event
 * @param additionalFields additional payload fields
 */
export const fireEvent = async (eventName: string, additionalFields?: Object): Promise<void> => {
  const body: Record<string, any> = {
    eventName,
    domainUserId: await snowplow.getDomainUserId(),
  };

  if (additionalFields != null) body.additionalFields = additionalFields;

  let response;

  try {
    response = await fetchWrapper(`${getApiBaseUrl()}/${API_VERSION}/events`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (e) {
    // fail gracefully
  }

  return response;
};

/**
 * This methods can be used for events that happen inside the login wall
 * @param eventName the Schema registry name of the event
 * @param additionalFields additional payload fields
 */
export const fireAccountEvent = async (eventName: string, additionalFields?: Object): Promise<void> => {
  const body: Record<string, any> = {
    eventName,
    domainUserId: await snowplow.getDomainUserId(),
  };

  if (additionalFields != null) body.additionalFields = additionalFields;


  let response;

  try {
    response = await fetchWrapper(`${getApiBaseUrl()}/${API_VERSION}/account/events`, {
      headers: {
        Authorization: `Bearer ${getJwT()}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (e) {
    // fail gracefully
  }

  return response;
};

/**
 * Track instruct event and send it to avalanche
 * @param {*} schema
 * @param {*} data
 */
export const trackUnStructEvent = (schema, data, context = null) => {
  if (process.env.NODE_ENV !== 'production') return;
  if (typeof window.snowplow === 'undefined') return;
  window.snowplow(
    `trackUnstructEvent:${AVALANCHE_TRACKER_NAME}`,
    {
      schema,
      data,
    },
    context,
  );
};

export const trackStructEvent = ({
  category = 'account',
  action,
  label,
  property,
}): void => {
  if (process.env.NODE_ENV !== 'production') return;
  window.snowplow(
    `trackStructEvent:${AVALANCHE_TRACKER_NAME}`,
    category,
    action,
    label,
    property,
    null,
  );
};

export const trackNextStepCTAClick = ({
  label,
  property,
}: {label: string, property: string}): void => {
  trackStructEvent({
    category: 'account',
    action: 'next_step_cta_click',
    label,
    property,
  });
};

// [CyberXSellTest] Temporary code for the Cyber Cross Sell test.
// This code will either need to be refactored or removed.
// https://simplybusiness.atlassian.net/browse/RTNRS-724
export const trackXSellCTA = (): void => {
  trackStructEvent({
    category: 'account',
    action: 'drop_down',
    label: 'get_extra_coverage_for_your_business',
    property: 'homepage',
  });
};

export const trackLinkClick = ({
  url,
  label,
  property,
}: {url?: string, label: string, property?: string}): void => {
  trackStructEvent({
    category: 'account',
    action: 'link_click',
    label,
    property: property || url || window.location.href,
  });
};

export const trackBannerImpression = ({
  category,
  label,
  property,
}): void => {
  trackStructEvent({
    category,
    action: 'banner_viewed',
    label,
    property,
  });
};

export const trackBannerClick = ({
  category,
  label,
  property,
}): void => {
  trackStructEvent({
    category,
    action: 'banner_clicked',
    label,
    property,
  });
};

export const trackPageView = (): void => {
  if (process.env.NODE_ENV !== 'production') return;
  /**
   * Track page view event through snowplow collector
   * @param {*} name of event
   * @param {*} custom title (nullable)
   * @param {Array} contexts (must be array, even if empty)
   */
  window.snowplow(
    `trackPageView:${PV_VALANCHE_TRACKER_NAME}`,
    null,
    [
      {
        schema: 'iglu:com.simplybusiness/distribution_channel_context/jsonschema/1-0-0',
        data: {
          service_channel_identifier: process.env.SERVICE_CHANNEL_IDENTIFIER,
        },
      },
      {
        schema: 'iglu:com.simplybusiness/service_channel_context/jsonschema/1-0-0',
        data: {
          service_channel_identifier: process.env.SERVICE_CHANNEL_IDENTIFIER,
        },
      },
    ],
  );
};

export const init = async (): Promise<void> => {
  snowplow = new Snowplow();// TODO: refactor this
  trackPageView();

  if (window.usabilla_live) {
    window.usabilla_live('data', {
      custom: {
        domain_userid: await snowplow.getDomainUserId(),
      },
    });
  }
};

export const snowplowDUID = async () => snowplow.getDomainUserId();

export default {
  init,
  fireEvent,
  fireAccountEvent,
  snowplowDUID,
  trackLinkClick,
  trackPageView,
  trackBannerImpression,
  trackBannerClick,
  trackXSellCTA,
  trackNextStepCTAClick,
  trackStructEvent,
  trackUnStructEvent,
};
