import { isPast, isToday, parseISO } from 'date-fns';

export enum policyStates {
  active = 'active',
  pending = 'pending',
  cancelled = 'cancelled',
  expired = 'expired',
}

const isPolicyEndDateInPast = (policyEndDate): boolean => {
  const parsedEndDate = parseISO(policyEndDate).getTime();

  return ! isToday(parsedEndDate) && isPast(parsedEndDate);
};

const isPending = (policyStartDate): boolean => {
  const parsedStartDate = parseISO(policyStartDate).getTime();

  return parsedStartDate > Date.now();
};

// Returns the same date at midnight but with the client timezone
const enforceMidnight = (dateWithTime: String) => {
  // expected yyyy-mm-ddT00:00:00.000+01:00 (BST)
  // or       yyyy-mm-ddT00:00:00.000+00:00 (UTC)
  // the time and timezone from the dateWithTime does not matter
  const date = dateWithTime.match(/\d{4}-\d{2}-\d{2}/)?.[0];

  if (! date) return null;

  return new Date(date.concat('T00:00:00.000'));
};

interface Document {
  type: string;
  key: string;
  path: string;
  fileName: string;
}

interface Cover {
  name: string;
  displayName: string;
  displayLimit: string;
  covered: boolean;
}

interface Segment {
  riskDetails: RiskDetails;
  documents: Document[];
  covers: Cover[];
}

interface PolicyData {
  state: string;
  segments: Segment[];
  productDisplayNames: string[];
  [key: string]: unknown; // Catch all
}

interface RiskDetails {
  businessName: string;
  address1: string;
  address2: string;
  town: string;
  postalCode: string;
}

type PolicyState = ValueOf<typeof policyStates>;

export default class Policy {
  segments!: Segment[];

  startDate!: Date;

  endDate!: Date;

  vertical!: string;

  category!: string;

  policyNumber!: string;

  customerAccountIdentifier!: string;

  state!: PolicyState;

  id!: string; // TODO: Check this

  productDisplayNames!: string[];

  allProductNames!: string;

  isCurrent!: boolean;

  invoices!: Document[];

  canOptOutOfAutoRenewal!: boolean;

  distributionPartner!: string;

  /*
  The current "state" attribute we have in our system (Chopin)
  does't really reflect the actual state of the policy.
  This method is meant to help in displaying the Policy state in the front-end.
  The state values handled within this project are:
  - active
  - pending
  - expired
  - cancelled
  The rest of the front-end code must reference only this set of values.
  */

  convertState = ({
    state: rawState,
    endDate,
    startDate,
  }: PolicyData): PolicyState => {
    const state = rawState.toLowerCase();

    if (state === 'cancelled') return policyStates.cancelled;
    if (isPending(startDate)) return policyStates.pending;

    if (state === 'expired' || isPolicyEndDateInPast(endDate)) return policyStates.expired;

    if (['active', 'cancellation_scheduled', 'renewed'].includes(state)) return policyStates.active;

    return policyStates.active;
  };

  private autoRenewalOptOutEligibility(state: string): boolean {
    return ['active', 'cancellation_scheduled', 'pending'].includes(
      state.toLowerCase(),
    );
  }

  get isSBDistributionPartner(): boolean {
    return this.distributionPartner?.toLowerCase() === 'simplybusiness';
  }

  allProductDisplayNames = (policyData: PolicyData): string => policyData.productDisplayNames.join(', ');

  constructor(policyData) {
    const state = this.convertState(policyData);
    const allProductNames = this.allProductDisplayNames(policyData);
    const canOptOutOfAutoRenewal = this.autoRenewalOptOutEligibility(
      policyData.state,
    );

    Object.assign(this, {
      ...policyData,
      state,
      allProductNames,
      canOptOutOfAutoRenewal,
      startDate: enforceMidnight(policyData.startDate),
      endDate: enforceMidnight(policyData.endDate),
      isCurrent: [policyStates.active, policyStates.pending].includes(state),
    });
  }
}
