import React from 'react';
import styled from '@emotion/styled';
import { differenceInCalendarDays } from 'date-fns';
import { useTranslation } from 'react-i18next';


import { policyStates } from 'models/Policy';

const Indicator = styled.div`
  padding: 5px 8px 3px;
  line-height: var(--line-height-tight);
  display: inline-block;
  border-radius: var(--radius-2);
  font-size: var(--font-size-small-print);
  font-weight: var(--font-weight-bold);
  text-align: center;
`;


const Expired = styled(Indicator)`
  background: var(--color-error);
  color: var(--color-text-inverted);
`;

const Active = styled(Indicator)`
  background: var(--color-valid);
  color: var(--color-text-inverted);
`;

const Pending = styled(Indicator)`
  background: var(--color-background);
  color: var(--color-valid);
  border: 1px solid var(--color-valid);
`;

const Cancelled = styled(Indicator)`
  background: var(--color-error);
  color: var(--color-text-inverted);
`;

interface Props{
  // state of the policy (EXPIRED, CANCELLED, ACTIVE or PENDING)
  state: policyStates,
  // Start Date should be provided if the state of the policy is "pending"
  startDate?: Date,
}

export const StateIndicator = ({
  state, startDate,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  if (state === policyStates.expired) return <Expired>EXPIRED</Expired>;

  if (state === policyStates.cancelled) return <Cancelled>{t('state_indicator.cancelled')}</Cancelled>;

  if (state === policyStates.active) return <Active>ACTIVE</Active>;

  const daysBeforeStart = startDate && differenceInCalendarDays(new Date(startDate), new Date());

  return (
    <Pending data-testid="pending-indicator">
      {daysBeforeStart && daysBeforeStart > 1 ? `STARTS IN ${daysBeforeStart} DAYS` : 'STARTS TOMORROW'}
    </Pending>
  );
};


export default StateIndicator;
